.App {
  text-align: center;
  
}

body {
  overscroll-behavior: none; /* Disable overscrolling behavior */
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  overflow: auto; /* Allow regular scrolling */
}
html{
  background-image: url(repeaterr.png);
  background-color: black;
  overscroll-behavior: none; /* Disable overscrolling behavior */

  overflow: auto; /* Allow regular scrolling */
  
}
body::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
}

.herostuff{
  position:absolute;
  top: calc(35% - 3vw);
  left:50%;
  right:50%;
  
}
.App-header {
  
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #cdb876;
  font-family: arial;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: .075rem;
  
}

/* Navigation links */
.navbar {
margin: 0px;
position: absolute;
right: 5%;
top: 6.1%;
font-size: calc(10px + 1.1vmin);
width:calc(50px + 35%);
}

.navbar ul{
  display: flex;
  list-style: none;
  justify-content: space-around;
}

.navbar a{
  font-family: arial;
  color: #cdb876;
  text-decoration: none;
  font-weight: 100;
  letter-spacing: .075rem;
}

/* Hamburger Menu Styles */
.ham-menu {
  height: calc(1.3vw + 15px);
  width: calc(1.3vw + 20px);
  position: absolute;
  top: 7%;
  right: 8%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
}

.ham-menu span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #cdb876;
  border-radius: 25px;
  transition: 0.3s ease;
}

.ham-menu span:nth-child(1)
{
top:20%;
}
.ham-menu span:nth-child(3)
{
top:80%;
}

/* Add a background color on mouse-over */
.navbar a:hover {
  
  cursor:pointer;
}

/* Style the current/active link */
.navbar a.active {
  
}

.topline{
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.hero{
  font-size: calc(1px + 6.5vmin);
  text-align: center;
  
}

.herocontainer{
  text-align: center;
  width:75%;
  display: flex;
  justify-content: center ;
  line-height: 1.4;

}

.logo{
  position: absolute;
  margin: 0px;
  left: 8%;
  top: 6.75%;
  font-family: athelas;
  letter-spacing: .2rem;
  font-size: calc(4vmin + 6px);
  color: #cdb876;
  cursor: pointer;
  text-decoration: none;
  font-weight:bold;
}

/* Hide Hamburger Menu When Navbar is Open */
.ham-menu.hidden {
  display: none; /* Hides the hamburger menu */
}


.navbar.open ul{
  margin-top:0px;
  display: flex;
  line-height: 30px;
  text-align: right;
  flex-direction: column; /* Stack items vertically */
  position: absolute;
  top: 45px; /* Position below the header */
  right: 8%;
  font-size: 20px;
  line-height: 1.7;
  
  
}

@media (max-width: 1000px) {
/* Navbar Visible When Menu is Open */

.navbar {
  display: none; /* Hide the full navigation bar on small screens */
}

.navbar.open {
  display: flex;
}

/* Show Close Menu Button When Menu is Open */
.close-menu.visible {
  display: flex; /* Show when menuOpen is true */
}

}

.centerphoto {
  display:flex;
 width:100%;
  justify-content: center; /* Center the image horizontally */
   /* Ensure it spans the full width */
}

  @media (max-width: 700px) {
    .contact-card {
      flex-direction: column; /* Stack content vertically */
      align-items: center; /* Center content horizontally */
      justify-content: center;
      text-align: center; /* Center-align text */
      
      
    
      
    }
  
    .profile-photo-about {
      width: 100px;
      height: 100px;
      border-radius: 50%; /* Circular image */
      margin-bottom:30px; /* Center image and add spacing below */
      
      border: 1.5px solid #cdb876; /* Keep the gold border */
    }
  
  }


@media (min-width: 1000px) {
 
  .navbar.open ul{
    display: none;
  }
  .ham-menu {

    display: none; /* Hide the full navigation bar on small screens */
    
  }
  .close-menu {
    display: none;
  }

}

@font-face {
  font-family: athelas;
  src: url(athelas.ttc);
  letter-spacing: .2rem;
}

.buttongroup{
  
  width:75%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.buttongroup button{
  color: #cdb876;
  background: transparent;
  border: 1px solid #cdb876;
  
  margin: 2.5vmin;
  
  border-radius: 60px;
  padding-left: 6vmin;
  padding-right: 6vmin;
  padding-top: 3vmin;
  padding-bottom: 3vmin;
  letter-spacing: .05rem;

  font-size: calc(5px + 3.25vmin);
  
}

.buttongroup button:hover{
cursor: pointer;
}


.contact-container {
  margin-top: 200px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 0px;
  
  flex-wrap: wrap;
  margin-bottom:120px;
}
.contact-card a {
  color: #cdb876;
  text-decoration: none;
}
.info {
  text-align: left;
}

.info h3 {
  margin: 0 0 10px 0;
  font-size: calc(17px + 2vmin);
}

.info p {
  margin: 7px 0;
  font-size: calc(10px + 1vmin);
}
.contact-card {
  background-color: transparent;
  align-items:center;
  justify-content: center;
  display:flex;
  padding:40px;
  padding-top: 40px;
  padding-bottom: 40px;
  border: 1px solid #cdb876;
  border-radius: 20px;
  width: calc(60vw + 20px);
  max-width: 500px; /* Fixed card width */
  text-align: left;
  margin-left:40px;
  margin-right:40px;
  margin-top:20px;
  margin-bottom:10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-card a:hover {
  
  text-decoration: underline;
  
}
.contact-card:hover {
  transform: translateY(-5px);
  
  
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  margin-bottom: 80px;
  margin-top: calc(15vmin + 70px); /* Specifically adds space below the header */
  max-width: 900px; /* Centers the content and limits the width */
}
.secondary{
font-size:2.5vmin;
font-weight: 100;
line-height: 1.8;
opacity: 90%;
letter-spacing: .12rem;
text-align: center;
align-items: center;
}
.name{
  margin-bottom:0px;
  font-size: 6vmin;
  text-align: center;
  align-items: center;
}

.primary{
  font-size:3.5vmin;
  margin-top: 10vmin;
  margin-bottom: 2vmin;
  letter-spacing: .12rem;
  text-align: center;
  align-items: center;
}

.mediation{
  font-weight: 100;
  font-size:4vmin;
  margin-top:2.2vmin;
  margin-bottom:12vmin;
  opacity: 90%;
  text-align: center;
  align-items: center;
  }
.info{
  margin-left:23px;
  margin-right:23px;
}
.profile-card {
  
  width: 100%; /* Allows it to shrink responsively */
  padding: 20px;
  
  border-radius: 10px;
  
  color: #cdb876; /* Gold text */
  margin-bottom: 30px; /* Adds space between sections */
}
.profile-photo-about{
  width: 120px; /* Fix image size */
  height: 120px;
  border-radius: 50%; /* Make it circular */
  
  
  border: 1.5px solid #cdb876; /* Add a gold border */
}
.profile-photo {
  width: calc(20vmin + 70px); /* Fix image size */
  height: calc(20vmin + 70px);
  border-radius: 50%; /* Make it circular */
  margin-bottom: calc(2vmin); /* Center image and add spacing below */
  border: 2px solid #cdb876; /* Add a gold border */
}
/* Default Close Menu Button (X) */
.close-menu {
  display: none; /* Hidden by default */
  height: calc(1.3vw + 15px);
  width: calc(1.3vw + 20px);
  position: absolute;
  top: 7%;
  right: 8%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 101;
}



/* X Button Styling */
.close-menu span {
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #cdb876;
  border-radius: 25px;
  transition: all 0.3s ease;
}

/* First Line of X */
.close-menu span:nth-child(1) {
  transform: rotate(45deg);
}

/* Second Line of X */
.close-menu span:nth-child(2) {
  transform: rotate(-45deg);
}

